<template>
    <div>
        <div id="home" class="consulting-background vh-100" style="padding-top: 7rem;">
            <!-- Home Page -->
            <div id="first-container" class="container mx-auto row">
                <div id="TatSing-Consulting" class="my-5 col-sm-12 col-md-6 h-100">
                    <div class="position-relative h-100"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true">
                        <img class="mt-5" src="@/assets/img/TAT-SING-consulting-group-black.png" alt="TatSing-Consulting-Logo">

                        <p class="fs-2 lh-sm my-5">Feeling good about <br> making a <strong class="text-theme">difference</strong>.</p>

                        <a href="#projects" class="text-decoration-none"><button type="button" class="text-white p-3 rounded d-flex align-items-center gap-3 bg-theme border-0 justify-content-center" style="width: 160px;">Read More <img src="@/assets/img/icons/arrow-down.png" alt="arrow-down" height="16"></button></a>
                    </div>
                </div>
            </div>
        </div>

        <div id="projects" class="bg-light p-5">
            <div class="container d-flex flex-column gap-5">
                <h1 class="fw-bold text-center fs-1 w-50 m-auto">We Completed 100+ Successful Projects Yearly & Counting</h1>
                <div class="d-flex justify-content-evenly justify-content-md-evenly justify-content-sm-between text-center counter">
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-center">
                            <span class="fs-3 fw-bold text-theme datacounter" data-counter="300"></span>
                            <span class="fs-3 fw-bold text-theme" v-show="isCounting">+</span>
                        </div>
                        <span class="text-gray">Serviced Clients</span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="fs-3 fw-bold text-theme datacounter" data-counter="1012"></span>
                        <span class="text-gray">Completed Projects</span>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-center">
                            <span class="fs-3 fw-bold text-theme datacounter" data-counter="300"></span>
                            <span class="fs-3 fw-bold text-theme" v-show="isCounting">+</span>
                        </div>
                        <span class="text-gray">On-going Projects</span>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-center">
                            <span class="fs-3 fw-bold text-theme datacounter" data-counter="861"></span>
                            <span class="fs-3 fw-bold text-theme" v-show="isCounting">M</span>
                        </div>
                        <span class="text-gray">Total Income</span>
                    </div>
                </div>
            </div>
        </div>

        <div id="about" class="vh-100 container p-5">
            <div class="position-relative w-100 d-flex flex-column"
                data-aos="fade-down"
                data-aos-offset="100"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true">
                <span class="fs-3 fw-bold text-uppercase px-1 text-theme">About Us</span>
                <span class="position-absolute h-100 border-bottom border-5 left-0 my-1 border-secondary-theme underline"></span>
            </div>
            <div class="py-4">
                <div data-aos="fade-down"
                data-aos-offset="100"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true">
                    <span class="display-5 fw-bold">Why choose Tat Sing <br> Consulting Group?</span>
                </div>
            
                <div class="row p-5 gap-5 text-center">
                    <div class="col-12 col-sm-12 col-md mx-auto"
                        data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">
                        <img src="@/assets/img/icons/consultant.png" alt="consultant" class="m-auto">
                        <div>
                            <h1 class="fw-bold mt-3 mb-4 fs-2">We are IT experts</h1>
                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse quam id quasi unde iure! Optio!</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md mx-auto"
                    data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-duration="1500"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">
                        <img src="@/assets/img/icons/service.png" alt="service">
                        <div>
                            <h1 class="fw-bold mt-3 mb-4 fs-2">Flexible Service</h1>
                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse quam id quasi unde iure! Optio!</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md mx-auto"
                    data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-duration="2000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">
                        <img src="@/assets/img/icons/flow.png" alt="flow">
                        <div>
                            <h1 class="fw-bold mt-3 mb-4 fs-2">High-level <br> Experience</h1>
                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse quam id quasi unde iure! Optio!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="services" class="bg-light vh-100 d-flex flex-md-column flex-column position-relative overflow-hidden">
            <div class="container w-100">
                <div class="col-6 my-5 px-5"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true">
                    <div class="m-auto position-relative w-100 d-flex flex-column">
                        <span class="fs-3 fw-bold text-uppercase px-1 text-theme">Services</span>
                        <span class="position-absolute h-100 border-bottom border-5 left-0 my-1 border-secondary-theme underline"></span>
                    </div>

                    <div class="m-auto d-flex flex-column h-100 position-relative">
                        <h1 class="display-4 fw-bold mt-4 mb-5">What does Tat Sing Consulting do?</h1>
                        <div class="w-100">
                            <img src="@/assets/img/data-graph.jpg" alt="data-graph" class="w-75">
                            
                            <div>
                                <div class="testimonials-slider swiper position-relative p-0 m-0">
                                    <div class="swiper-wrapper">
                        
                                        <div class="swiper-slide">
                                            <div class="testimonial-item w-75">
                                                <h1 class="fs-3 fw-bold my-2">Data Science and Analytics</h1>
                                                <p>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora sit minus doloremque quasi exercitationem similique?
                                                </p>
                                            </div>
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="testimonial-item w-75">
                                                <h1 class="fs-3 fw-bold my-2">Data Science and Analytics1</h1>
                                                <p>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora sit minus doloremque quasi exercitationem similique?
                                                </p>
                                            </div>
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="testimonial-item w-75">
                                                <h1 class="fs-3 fw-bold my-2">Data Science and Analytics2</h1>
                                                <p>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora sit minus doloremque quasi exercitationem similique?
                                                </p>
                                            </div>
                                        </div>
                        
                                    </div>
                                    <div class="mt-3 h-25">
                                        <div class="swiper-pagination text-start text-theme bottom-0"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-50 position-absolute h-100 end-0" 
            data-aos="fade-left"
            data-aos-offset="100"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true">
                <img src="@/assets/img/tablet-graph.png" alt="tablet-graph" class="h-100 w-100" style="object-fit: cover; object-position: center;">
            </div>
        </div>

        <div id="more-about-us" class="vh-100 mt-5">
            <div class="px-5 container"
                data-aos="fade-right"
                data-aos-offset="100"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true">
                <div class="position-relative w-100 d-flex flex-column">
                    <span class="fs-3 fw-bold text-uppercase px-1 text-theme">More About Us</span>
                    <span class="position-absolute h-100 border-bottom border-5 left-0 my-1 border-secondary-theme underline"></span>
                </div>

                <h1 class="display-4 fw-bold mt-4 w-75">Meet out experts consultants</h1>
            </div>
            <div class="consultant-slider swiper position-relative my-5" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="testimonial-item bg-light">
                            <img src="@/assets/img/vicky-sim-mj-2.png" alt="consultant-1" class="w-100">
                            <div class="text-center px-2 py-4">
                                <h1 class="fs-4 fw-bold">Lorem</h1>
                                <h1 class="fs-6">Senior IT Consultant</h1>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-item bg-light">
                            <img src="@/assets/img/vicky-sim-mj-3.png" alt="consultant-1" class="w-100">
                            <div class="text-center px-2 py-4">
                                <h1 class="fs-4 fw-bold">Lorem</h1>
                                <h1 class="fs-6">Senior IT Consultant</h1>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-item bg-light">
                            <img src="@/assets/img/vicky-sim-mj-4.png" alt="consultant-1" class="w-100">
                            <div class="text-center px-2 py-4">
                                <h1 class="fs-4 fw-bold">Lorem</h1>
                                <h1 class="fs-6">Senior IT Consultant</h1>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-item bg-light">
                            <img src="@/assets/img/vicky-sim-mj-5.png" alt="consultant-1" class="w-100">
                            <div class="text-center px-2 py-4">
                                <h1 class="fs-4 fw-bold">Lorem</h1>
                                <h1 class="fs-6">Senior IT Consultant</h1>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-item bg-light">
                            <img src="@/assets/img/vicky-sim-mj-6.png" alt="consultant-1" class="w-100">
                            <div class="text-center px-2 py-4">
                                <h1 class="fs-4 fw-bold">Lorem</h1>
                                <h1 class="fs-6">Senior IT Consultant</h1>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-item bg-light">
                            <img src="@/assets/img/vicky-sim-mj-7.png" alt="consultant-1" class="w-100">
                            <div class="text-center px-2 py-4">
                                <h1 class="fs-4 fw-bold">Lorem</h1>
                                <h1 class="fs-6">Senior IT Consultant</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "../assets/vendor/swiper/swiper-bundle.min.js"

export default {
  name: 'Home',
  data() {
    return {
      isCounting: false,
      inter: ''
    }
  },
  mounted() {
    document.addEventListener('scroll', () => {
      this.count()
    })   

   new Swiper('.testimonials-slider', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });

   new Swiper('.consultant-slider', {
    speed: 300,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    breakpoints: {
      // when window width is >= 0
      0: {
          slidesPerView: 'auto',
      },
      // when window width is >= 601
      601: {
          slidesPerView: 2,
          spaceBetweenSlides: 20
      },
      // when window width is >= 1024
      1024: {
        slidesPerView: 4.5,
        spaceBetween: 60,
      }
  }
  });
  },
  methods: {
    checkVisible(el, container, threshold, mode) {
        threshold = threshold || 0;
        mode = mode || 'visible';

        var rect = el.getBoundingClientRect();
        var viewHeight = Math.max(container, window.innerHeight);
        var above = rect.bottom - threshold < 0;
        var below = rect.top - viewHeight + threshold >= 0;
        
        return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
    },
    count() {
        if(this.$route.path == '/') {
            if(!this.isCounting) {
                const projects_id = document.getElementById('projects')
                if(this.checkVisible(projects_id, document.documentElement.clientHeight)) {
                this.isCounting = true
                const counters = document.querySelectorAll('.datacounter')
                counters.forEach((counter) => {
                    if(counter.innerText !== counter.getAttribute('data-counter')) {
                    counter.innerText = '0'
                    
                    const updateCounter = () => {
                        const end = +counter.getAttribute('data-counter')
                        const c = +counter.innerText

                        const increment = end / 200

                        if(c < end) {
                        counter.innerText = `${Math.ceil(c + increment)}`
                        requestAnimationFrame(updateCounter)
                        }
                        else {
                        counter.innerText = end
                        }
                    }
                    updateCounter()
                    }
                })
                }
            }
        }
      return
    },
    debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }
  }
}
</script>
